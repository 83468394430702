<template>
  <div class="statistic">
    <div class="dataSearchDelayTips">
      {{ $t("tips.dataSearchDelay") }}
    </div>
    <div class="panel-title">
      {{ $t("statistic") }}
    </div>

    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-form :form="searchForm">
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('enterpriseCode')">
                <el-input clearable v-model="searchForm.entName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('statisticTimeRange')">
                <search-date
                  class="search-date"
                  :data.sync="searchForm.searchDate"
                  :format="format"
                  style="width: 100%;"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <rating-popover
            :defaultVisible="false"
            rateTitle="機能改善、サービス品質向上のためにアンケートにご協力お願いします。"
            :rateData="[
              {
                title: '集計データの満足度を星1つ（かなり不満）から星5つ（大満足）の範囲で教えてください。',
                type: 'star',
                platform: 'StarPay-Works',
                entCode: $store.state.app.userInfo.entName,
                entName: $store.state.app.userInfo.entDisplayName,
                userName: $store.state.app.userInfo.userName,
                oem: $settings.oem
              }
            ]"
            placement="bottom"
            :showFrequency="userInfo.isMasterEnterprise ? 'never' : $settings.oem == 'netstars' ? 'once' : 'never'"
          >
            <el-button class="confirm" @click="getList(1)" :disabled="loading">{{
              $t("search")
            }}</el-button></rating-popover
          >
        </div>
      </div>

      <div class="summary-container">
        <div class="summary-title">
          {{ $t("statisticByDateRange") }}
        </div>
        <el-row :gutter="20">
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("statisticTotalCount") }}</div>
              <div class="value">{{ numFormat(totalCount) }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("statisticTotalAmount") }}</div>
              <div class="value">{{ moneyFormat(totalAmount) }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("statisticPayCount") }}</div>
              <div class="value">{{ numFormat(payCount) }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("statisticPayAmount") }}</div>
              <div class="value">{{ moneyFormat(payAmount) }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("statisticRefundCount") }}</div>
              <div class="value">{{ numFormat(refundCount) }}</div>
            </div></el-col
          >

          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("statisticRefundAmount") }}</div>
              <div class="value">{{ moneyFormat(refundAmount) }}</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <div class="statistics-table">
      <el-tabs v-model="tabType" @tab-click="tabClick">
        <el-tab-pane :label="$t('listByDate')" name="1">
          <div class="result-background">
            <div class="result-table ">
              <el-table :data="statMonRecordList" v-loading="loading">
                <el-table-column prop="tradeDate" :label="$t('date')" width="150">
                  <template slot-scope="scope">
                    {{ dateFormat(scope.row.tradeDate, "YYYY/MM/DD") }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalCount" :label="$t('listColTotalCount')" min-width="120">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.totalCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalAmount" :label="$t('listColTotalAmount')" min-width="150">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.totalAmount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="payCount" :label="$t('listColPayCount')" min-width="120">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.payCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="payAmount" :label="$t('listColPayAmount')" min-width="150">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.payAmount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundCount" :label="$t('listColRefundCount')" min-width="120">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.refundCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundAmount" :label="$t('listColRefundAmount')" min-width="150">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.refundAmount) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <el-pagination
              background
              layout=" prev, pager, next, sizes, total"
              :total="total"
              :page-size="currentPageSize"
              :page-sizes="[10, 15, 20]"
              :current-page="currentPage"
              @current-change="currentChange"
              @size-change="sizeChange"
              class="common-pagination"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('listByCode')" name="0">
          <!-- 按照店铺Code统计 -->
          <div class="result-background">
            <div class="result-table">
              <el-table :data="statShopRecordList" v-loading="loading" v-if="statShopRecordList">
                <el-table-column prop="storeId" :label="$t('storeCode')" width="300"> </el-table-column>
                <el-table-column prop="storeName" :label="$t('storeName')" min-width="200"> </el-table-column>
                <el-table-column prop="totalCount" :label="$t('listColTotalCount')" min-width="150">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.totalCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalAmount" :label="$t('listColTotalAmount')" min-width="130">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.totalAmount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="payCount" :label="$t('listColPayCount')" min-width="150">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.payCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="payAmount" :label="$t('listColPayAmount')" min-width="170">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.payAmount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundCount" :label="$t('listColRefundCount')" min-width="250">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.refundCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundAmount" :label="$t('listColRefundAmount')" min-width="210">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.refundAmount) }}
                  </template>
                </el-table-column>
              </el-table>
              <!-- 按照子企业Code统计 -->
              <el-table :data="statEntRecordList" v-loading="loading" v-else>
                <el-table-column prop="entName" :label="$t('enterpriseCode')" width="300"> </el-table-column>
                <el-table-column prop="entInfo" :label="$t('enterpriseName')" width="300"> </el-table-column>
                <el-table-column prop="totalCount" :label="$t('listColTotalCount')">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.totalCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalAmount" :label="$t('listColTotalAmount')">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.totalAmount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="payCount" :label="$t('listColPayCount')">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.payCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="payAmount" :label="$t('listColPayAmount')">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.payAmount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundCount" :label="$t('listColRefundCount')">
                  <template slot-scope="scope">
                    {{ numFormat(scope.row.refundCount) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundAmount" :label="$t('listColRefundAmount')">
                  <template slot-scope="scope">
                    {{ moneyFormat(scope.row.refundAmount) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <el-pagination
              background
              layout=" prev, pager, next, sizes, total"
              :total="total"
              :page-size="currentPageSize"
              :page-sizes="[10, 15, 20]"
              :current-page="currentPage"
              @current-change="currentChange"
              @size-change="sizeChange"
              class="common-pagination"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-button
        class="cancel statistics-download-btn"
        v-loading="downloadLoading"
        :disabled="downloadLoading"
        @click="downloadClick"
        v-permission="'Stat.StatDownload'"
        icon="el-icon-download"
      >
        {{ $t("dataDownload") }}
      </el-button>
    </div>

    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container" v-loading="downloadLoading">
        <div class="download-type-item" @click="downloadData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { getSessionStorage } from "@/utils/storage";
import { statisticsList, downloadStatisticFile } from "api/statistics";
import { moneyFormat, numFormat } from "utils";
import { dateFormat, diffDays } from "utils/date";
import pagination from "@/mixins/pagination";
import { executeDownload } from "utils/download";

export default {
  name: "Statistics",
  mixins: [pagination],
  data() {
    return {
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("month")
            .format("YYYY/MM/DD"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD")
        ],
        entName: "",
        statType: 0 //集計类型 0 コード別で表示 1 日付順で表示
      },
      format: "yyyy/MM/dd",
      tabType: "1",
      loading: false,
      downloadLoading: false,
      statShopRecordList: [],
      statEntRecordList: [],
      statMonRecordList: [],
      totalCount: 0,
      totalAmount: 0,
      payCount: 0,
      payAmount: 0,
      refundCount: 0,
      refundAmount: 0,
      userInfo: {},
      options: {},
      downloadDialog: false
    };
  },
  methods: {
    numFormat,
    moneyFormat,
    dateFormat,
    tabClick() {
      this.getList(1);
    },

    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.statShopRecordList = [];
      this.statEntRecordList = [];
      this.statMonRecordList = [];
      this.currentPage = pageIndex;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      if (diffDays(endTime, beginTime) > 31) {
        this.$message.warning(this.$t("statisticSearchPeriodLimit"));
        return;
      }
      this.loading = true;
      this.$store.commit("app/clearCancelToken");
      statisticsList({
        pageIndex,
        pageSize,
        beginTime,
        endTime,
        entName: this.searchForm.entName,
        statType: parseInt(this.tabType)
      }).then(response => {
        this.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.totalCount = response.result.totalCount;
          this.totalAmount = response.result.totalAmount;
          this.payCount = response.result.payCount;
          this.payAmount = response.result.payAmount;
          this.refundCount = response.result.refundCount;
          this.refundAmount = response.result.refundAmount;
          this.statShopRecordList = response.result.statShopRecordList;
          this.statEntRecordList = response.result.statEntRecordList;
          this.statMonRecordList = response.result.statMonRecordList;
          this.total = response.totalCount;
        }
      });
    },

    downloadData(fileType) {
      this.downloadDialog = false;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      this.downloadLoading = true;
      downloadStatisticFile({
        beginTime,
        endTime,
        EntName: this.searchForm.entName,
        StatType: this.tabType,
        FileFormat: fileType
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.downloadLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.downloadLoading = false;
        }
      });
    },

    downloadClick() {
      this.downloadDialog = true;
    }
  },

  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.searchForm.entName = this.userInfo.entName;
    // this.getList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.statistic-summary-table {
  display: inline-block;
  max-width: 450px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.statistics-table {
  margin-top: 20px;
  position: relative;

  ::v-deep .el-tabs__nav-wrap::after {
    width: calc(100% - 246px);
  }

  .statistics-download-btn {
    position: absolute;
    right: 1px;
    top: -2px;
  }
}
</style>
