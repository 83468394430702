import http from "../http";
//PSP集计
export const statisticsList = params => {
  return http({
    method: "get",
    url: `/api/statistics/list`,
    params
  });
};
//包括POINT 集计
export const statList = params => {
  return http({
    method: "get",
    url: `/api/statistics/statlist`,
    params
  });
};

export const downloadStatisticFile = params => {
  return http({
    method: "get",
    url: `/api/statistics/statisticexcel`,
    responseType: "blob",
    params
  });
};
// 前端下载
export const downloadrecord = params => {
  return http({
    method: "get",
    url: `/api/statistics/downloadrecord`,
    params
  });
};
//新下载集计记录,包含point
export const downloadStatFile = params => {
  return http({
    method: "get",
    url: `/api/statistics/statfile`,
    responseType: "blob",
    params
  });
};
